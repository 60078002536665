/*
* @Author: 曹俊杰
* @Date: 2024-09-23 11:28:59
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-12 15:46:35
*/权益
<template>
    <div class="equity">
        <div class="left">
            <div class="menu">
                <router-link class="item1 item" active-class="active" v-for="item in menuList" tag='div'
                    :key="item.name" :to='`/equity/${item.name}`' replace>
                    <i class="iconfont" :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div>
        <div class="right">
            <router-view />
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            menuList: [
                {
                    title: '权益执行',
                    name: 'execute',
                    icon: 'el-icon-ali-qianshuchuzheng'
                },
                {
                    title: '结束案件',
                    name: 'result',
                    icon: 'el-icon-ali-chuzhengjilu'
                }
            ]
        };
    },
    mounted() {

    },
    methods: {

    },
};
</script>
<style lang='scss' scoped>
.equity {
    display: flex;
    padding-top: 16px;

    .left {
        width: 208px;
        padding-left: 24px;
        padding-right: 16px;

        .menu {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            text-align: left;

            .item {
                width: 168px;
                height: 40px;
                top: 48px;
                gap: 0px;
                border-radius: 4px 0px 0px 0px;
                opacity: 0px;
                display: flex;
                padding: 0 20px;
                margin-bottom: 8px;
                align-items: center;
                cursor: pointer;

                i {
                    margin-right: 8px;
                }
            }

            .item2 {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .item3 {
                padding: 0 30px;
            }

            .active {
                background: #fff;
                border-radius: 4px;
                color: #0062FF;
            }

            .fold {
                //折叠
                height: 0px;
                overflow: hidden;
            }
        }
    }

    .right {
        flex: 1;
        padding-right: 20px;

    }
}
</style>